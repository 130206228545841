import React from 'react';
import { postsStyle } from '../assets/jss/pages/postsStyle';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, Link as GatsbyLink, PageRendererProps } from 'gatsby';
import { Grid, Link, Typography } from '@material-ui/core';
import { BlogPostNode } from '../components/BlogPost/BlogPost';
import { DefaultHeader } from '../components/Header/Header';
import { CardHeader } from '../components/Card/CardHeader';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import Img from 'gatsby-image';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

export const postsQuery = graphql`
  query {
    allBlogPosts: allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          slug
          author
          cover {
            fluid(maxWidth: 300, quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          teaser {
            teaser
          }
          createdAt(fromNow: true, locale: "zh-CN")
        }
      }
    }
  }
`;

interface PostsProps extends PageRendererProps {
  data: {
    allBlogPosts: {
      edges: [
        {
          node: BlogPostNode;
        },
      ];
    };
  };
}

const useStyles = makeStyles(postsStyle);

const BlogPosts: React.FC<PostsProps> = ({ data }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { allBlogPosts } = data;

  return (
    <div className={classes.blogPostPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'blogs.allBlogPosts' })}</title>
      </Helmet>
      <DefaultHeader />
      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.blogPosts" defaultMessage="Blog Posts" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              <FormattedMessage id="blogs.allBlogPosts" defaultMessage="All Blog Posts" />
            </Typography>

            {allBlogPosts.edges.map(({ node }) => {
              return (
                <Grid
                  container
                  key={node.contentful_id}
                  spacing={2}
                  className={classes.containerRaw}
                  alignItems="center"
                >
                  <Grid item md={4} sm={4} xs={12}>
                    <CardHeader image plain>
                      <GatsbyLink to={`/posts/${node.slug}`}>
                        <Img fluid={node.cover.fluid} />
                      </GatsbyLink>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${node.cover.fluid.src})`,
                          opacity: '1',
                        }}
                      />
                    </CardHeader>
                  </Grid>
                  <Grid item md={8} sm={8} xs={12}>
                    <h2 className={classes.cardTitle}>
                      <Link component={GatsbyLink} to={`/posts/${node.slug}`}>
                        {node.title}
                      </Link>
                    </h2>
                    <p className={classes.description}>
                      {node.teaser.teaser}
                      <Link component={GatsbyLink} to={`/posts/${node.slug}`}>
                        {' '}
                        <FormattedMessage id="general.readMore" defaultMessage="Read more" />
                      </Link>
                    </p>
                    <p>
                      <FormattedMessage
                        id="blogs.writtenBy"
                        defaultMessage="by {author}"
                        values={{ author: node.author }}
                      />
                      , {node.createdAt}
                    </p>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogPosts;
